<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
             <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                  {{ $t('movement.interGodownChallanReceive')}}
                </list-report-head>
              </b-col>
              </b-row>
              <b-row>
                <b-table-simple bordered style="font-size:12px;">
                  <tr>
                    <td style="width:20%">{{ $t('movement.program_no')}} </td>
                    <td class="text-center" style="width:2%">:</td>
                    <td style="width:28%">{{ formData.program_no }} </td>
                    <td style="width:20%">{{ $t('movement.loriChalanNo')}}</td>
                    <td class="text-center" style="width:2%">:</td>
                    <td  style="width:28%"> {{ formData.lori_challan_no }} </td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.fertilizer_name')}} </td>
                     <td class="text-center" style="width:2%">:</td>
                    <td> {{ $i18n.locale === 'en' ? formData.fertilizer_id.text_en : formData.fertilizer_id.text_bn }} </td>
                    <td>{{ $t('movement.deliveredAmount')}}</td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ $n(formData.delivered_amount) }}{{ '(' + $t('movement.mTon') + ')' }} </td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.deliveredDate')}} </td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ formData.delivered_date | dateFormat }} </td>
                    <td>{{ $t('movement.receivedAmount')}}</td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ $n(formData.received_amount) }}{{ '(' + $t('movement.mTon') + ')' }} </td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.receivedDate')}} </td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ formData.receive_date | dateFormat }} </td>
                    <td>{{ $t('movement.diffAmount') }} </td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ (formData.delivered_amount > formData.received_amount ? '(-)' : '') + $n(formData.delivered_amount - formData.received_amount) }}{{ '(' + $t('movement.mTon') + ')' }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('movement.duration') }}</td>
                    <td class="text-center" style="width:2%">:</td>
                    <td>{{ $n(formData.duration) }}</td>
                    <td>{{ $t('movement.comments')}} </td>
                    <td class="text-center" style="width:2%">:</td>
                    <td> {{ formData.comments }} </td>
                  </tr>
                </b-table-simple>
              </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interGodownChallanReceiveShow, reportHeadingList } from '@/modules/seeds-fertilizer-service/fertilizer/movement/api/routes'
import ListReportHead from '../../../fertilizer-reports/pages/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      this.getPortDetailsInfo()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        allocation_date: '',
        memo_no: '',
        lot_no: '',
        lc_no: '',
        ship_name: '',
        ship_name_bn: '',
        country_name: '',
        country_name_bn: '',
        fertilizer_id: '',
        fertilizer_name: '',
        bill_amount: '',
        total_allocated_amount: '',
        port_info: [],
        driver_name: '',
        program_no: '',
        amount_of_bag: '',
        fiscal_year_id: '',
        godown_infos_id: '',
        lori_challan_no: '',
        phone_no: '',
        transport_agent_id: '',
        agentMobile: '',
        comments: '',
        comments_bn: '',
        delivered_amount: '',
        received_amount: '',
        receive_date: '',
        delivered_date: '',
        duration: ''
      },
      org_id: 13,
      totalAvailableAmount: 0,
      currentAvailableAmount: 0,
      lcList: [],
      lcInfo: {},
      portDetailsInfo: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    portList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    importCountryList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getPortAllocationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getPortDetailsInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, interGodownChallanReceiveShow + '/' + this.id)
      let portDetailsInfo = []
      if (result.success) {
        this.formData.program_no = result.data !== 'undefined' ? result.data.program_no : ''
        this.formData.comments = result.data !== 'undefined' ? result.data.comments : ''
        this.formData.comments_bn = result.data !== 'undefined' ? result.data.comments_bn : ''
        this.formData.delivered_amount = result.data !== 'undefined' ? result.data.delivered_amount : ''
        this.formData.delivered_date = result.data !== 'undefined' ? result.data.delivered_date : ''
        this.formData.receive_date = result.data !== 'undefined' ? result.data.receive_date : ''
        this.formData.received_amount = result.data !== 'undefined' ? result.data.received_amount : ''
        this.formData.lori_challan_no = result.data !== 'undefined' ? result.data.lori_challan_no : ''
        this.formData.duration = this.getDateDifference(this.formData.delivered_date, this.formData.receive_date)
        const fertilizer = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(fer => fer.value === result.data.fertilizer_id)
        this.formData.fertilizer_id = fertilizer
      } else {
        portDetailsInfo = []
      }
      return portDetailsInfo
    },
    // pdfExport () {
    //   const rowData = []
    //   const chalanNo = this.formData.lori_challan_no
    //   const programNo = this.formData.program_no
    //   const fertilizer = this.formData.fertilizer_id
    //   const deliveredAmount = this.formData.delivered_amount
    //   const deliveredDate = this.formData.delivered_date
    //   const reciveAmount = this.formData.received_amount
    //   const reciveDate = this.formData.receive_date
    //   const comments = this.formData.comments
    //   // const date = 'date'
    //   const header = [
    //       { text: this.$t('movement.program_no'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: programNo },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: this.$t('movement.loriChalanNo'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10), margin: [70, 0, 0, 0] }, { text: ': ' }, { text: chalanNo }
    //   ]
    //   const header2 = [
    //       { text: this.$t('movement.fertilizer_name'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10), margin: [0, 0, 0, 0] }, { text: ': ', margin: [0, 0, 0, 0] }, { text: fertilizer, margin: [0, 0, 0, 0] },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: this.$t('movement.deliveredAmount'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10), margin: [70, 0, 0, 0] }, { text: ': ', margin: [0, 0, 0, 0] }, { text: deliveredAmount, margin: [0, 0, 0, 0] }
    //   ]
    //   const header12 = [
    //       { text: this.$t('movement.deliveredDate'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: deliveredDate },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: this.$t('movement.receivedAmount'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10), margin: [70, 0, 0, 0] }, { text: ': ' }, { text: reciveAmount }
    //   ]
    //   const header3 = [
    //       { text: this.$t('movement.receivedDate'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: reciveDate },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: this.$t('movement.diffAmount'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10), margin: [70, 0, 0, 0] }, { text: ': ' }, { text: deliveredAmount - reciveAmount }
    //   ]
    //   const header4 = [
    //       { text: this.$t('movement.comments'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: comments },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '' },
    //       { text: '', fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: '' }, { text: '' }
    //   ]
    //    // footer part
    //   const warehousekeeper = [
    //     ]

    //   const labels = [
    //     ]
    //   const extraData = {
    //     totalHeaderRows: 1,
    //     subHead: [header, header2, header12, header3, header4],
    //     footer: [warehousekeeper]
    //   }
    //   rowData.unshift(labels)
    //   // rowData.push(pdfData)
    //   const reportTitle = this.$i18n.locale === 'en' ? 'Inter Godown Challan Receive' : 'কিউসি সনদপত্র'
    //   const columnWids = []
    //   ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, rowData, columnWids, extraData)
    // },
    // getPdfData () {
    //     return true
    // }
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Inter Godown Challan Receive' : 'আন্তঃ গোডাউন চলন প্রাপ্তি'
        ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this)
    },
    getDateDifference (a, b) {
      const date1 = new Date(a)
      const date2 = new Date(b)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
