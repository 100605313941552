import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '2%', '28%', '20%', '2%', '28%'],
              body: [
                [
                  { text: vm.$t('movement.program_no'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$n(data.program_no), style: 'td' },
                  { text: vm.$t('movement.loriChalanNo'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: data.lori_challan_no, style: 'td' }
                ],
                [
                  { text: vm.$t('movement.fertilizer_name'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$i18n.locale === 'en' ? data.fertilizer_id.text_en : data.fertilizer_id.text_bn, style: 'td' },
                  { text: vm.$t('movement.deliveredAmount'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$n(data.delivered_amount) + ' (' + vm.$t('movement.mTon') + ')', style: 'td' }
                ],
                [
                  { text: vm.$t('movement.deliveredDate'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: dateFormat(data.delivered_date), style: 'td' },
                  { text: vm.$t('movement.receivedAmount'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$n(data.received_amount) + ' (' + vm.$t('movement.mTon') + ')', style: 'td' }
                ],
                [
                  { text: vm.$t('movement.receivedDate'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: dateFormat(data.receive_date), style: 'td' },
                  { text: vm.$t('movement.diffAmount'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: vm.$n(data.delivered_amount - data.received_amount) + ' (' + vm.$t('movement.mTon') + ')', style: 'td' }
                ],
                [
                  { text: vm.$t('movement.comments'), style: 'td' },
                  { text: ':', style: 'th', alignment: 'center' },
                  { text: data.comments, style: 'td' },
                  { text: '', style: 'td' },
                  { text: '', style: 'td' },
                  { text: '', style: 'td' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          transport: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 5],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('inter-godown-challan-receive')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
