<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-formData">
                      <b-row>
                        <b-col lg="12">
                          <div class="card">
                          <div class="card-inside-title font-weight-bold text-capitalize text-center p-1 m-2 bg-light text-black">
                            {{ $t('movement.interGodownChallanReceive') }}
                          </div>
                        </div>
                        </b-col>
                      </b-row>
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Lori Challan No" vid="lori_challan_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lori_challan_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.loriChalanNo')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="lori_challan_no"
                                    v-model="formData.lori_challan_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('movement.loriChalanNo')"
                                    @keyup="getChalanInfo()"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No" vid="program_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="lori_challan_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    v-model="formData.program_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('movement.program_no')"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Transport Agent Name"  vid="transportAgentName" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transportAgentName"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.transportAgentName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="transport_agent_name"
                                    v-model="formData.transport_agent_name"
                                    v-if="currentLocale === 'en'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('movement.transportAgentName')"
                                    readonly
                                ></b-form-input>
                                <b-form-input
                                  id="transport_agent_name"
                                  v-model="formData.transport_agent_name_bn"
                                  :placeholder="$t('movement.transportAgentName')"
                                  v-else
                                  readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Ware house Name"  vid="warehouseName" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="warehouseName"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.warehouseName')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="warehouse_name"
                                    v-model="formData.warehouse_name"
                                    v-if="currentLocale === 'en'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('movement.warehouseName')"
                                    readonly
                                ></b-form-input>
                                <b-form-input
                                  id="warehouse_name"
                                  v-model="formData.warehouse_name_bn"
                                  :placeholder="$t('movement.fertilizer_name')"
                                  v-else
                                  readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name"  vid="fertilizer_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fertilizer_name"
                                  slot-scope="{ errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.fertilizer_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="fertilizer_name"
                                  v-model="formData.fertilizer_name"
                                  :placeholder="$t('movement.fertilizer_name')"
                                  v-if="currentLocale === 'en'"
                                  readonly
                                ></b-form-input>
                                <b-form-input
                                    id="fertilizer_name_bn"
                                    v-model="formData.fertilizer_name_bn"
                                    :placeholder="$t('movement.fertilizer_name')"
                                    v-else
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Delivered Amount"  vid="delivered_amount" >
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="delivered_amount"
                                  slot-scope="{ errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.deliveredAmount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    :placeholder="$t('movement.deliveredAmount')"
                                    id="delivered_amount"
                                    v-model="formData.delivered_amount"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Delivered Date"  vid="deliveredDate">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="deliveredDate"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.deliveredDate')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                  v-model="formData.delivered_date"
                                  :placeholder="$t('movement.deliveredDate')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Received Amount"  vid="received_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="received_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receivedAmount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="received_amount"
                                    v-model="formData.received_amount"
                                    :placeholder="$t('movement.receivedAmount')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <!-- <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="differences_amount"
                            >
                              <template v-slot:label>
                                {{$t('movement.diffAmount')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="differences_amount"
                                  v-model="differences_amount"
                              ></b-form-input>
                            </b-form-group>
                          </b-col> -->
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Receive Process"  vid="receive_process" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="receive_process"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receiveProcess')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.receive_process"
                                    :options="receiveProcess"
                                    id="receive_process"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="$t('movement.receiveProcess')"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value=null>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.receive_process ==='Barcode'">
                            <ValidationProvider name="Barcode No"  vid="barcode_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="barcode_no"
                                  slot-scope="{ errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.barcodeNo')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="barcode_no"
                                    v-model="formData.barcode_no"
                                    :placeholder="$t('movement.barcodeNo')"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="differences_amount"
                            >
                              <template v-slot:label>
                                {{$t('movement.diffAmount')}}
                              </template>
                              <b-form-input
                                  readonly
                                  id="differences_amount"
                                  v-model="differences_amount"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <!-- <b-col lg="6" sm="12">
                            <ValidationProvider name="Received Amount"  vid="received_amount" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="received_amount"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receivedAmount')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="received_amount"
                                    v-model="formData.received_amount"
                                    :placeholder="$t('movement.receivedAmount')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col> -->
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Received Date"  vid="receivedDate" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="receivedDate"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.receivedDate')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input class="form-control"
                                  v-model="formData.receive_date"
                                  placeholder="Select Date"
                                  id="receivedDate"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Comments"  vid="comments" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="comments"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.comments')}}
                                </template>
                                  <b-form-textarea
                                    id="remarks"
                                    v-model="formData.comments"
                                    placeholder=""
                                    v-if="currentLocale === 'en'"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <b-form-textarea
                                    id="remarks"
                                    v-model="formData.comments_bn"
                                    :placeholder="$t('movement.comments')"
                                    v-else
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interGodownChallanReceiveStore, interGodownChallanReceiveUpdate, loriChallanNoSearch } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
     if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.agentList(tmp.transport_agent_id)
      this.getWarehouse(tmp.to_warehouse_id)
    }
  },
  mounted () {
    flatpickr('#receivedDate', {})
    flatpickr('#deliveredDate', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      formData: {
        id: '',
        lori_challan_no: '',
        program_no: '',
        transport_agent_id: '',
        delivered_amount: '',
        receive_process: null,
        received_amount: null,
        receive_date: null,
        comments: null,
        comments_bn: null,
        fiscal_year_id: 0,
        fertilizer_id: '',
        from_warehouse_id: '',
        to_warehouse_id: '',
        delivered_date: '',
        warehouse_name: '',
        fertilizer_name: ''
      },
      receiveProcess: [
        { value: 'Manual', text: this.$t('movement.manual') },
        { value: 'Barcode', text: this.$t('movement.barcode') }
      ],
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      lcErrorMsg: ''
    }
  },
  computed: {
    transportAgentList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    differences_amount: function () {
      return this.formData.delivered_amount - this.formData.received_amount
    }
  },
  watch: {
    // 'formData.lori_challan_no': function (newVal, oldVal) {
    //   this.getChalanInfo()
    // }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      const agent = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.status === 1 && item.value === tmpData.transport_agent_id)
      tmpData.transport_agent_name = agent !== 'undefined' ? agent.text_en : ''
      tmpData.transport_agent_name_bn = agent !== 'undefined' ? agent.text_bn : ''
      const ferTi = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.status === 1 && item.value === tmpData.fertilizer_id)
      tmpData.fertilizer_name = ferTi !== 'undefined' ? ferTi.text_en : ''
      tmpData.fertilizer_name_bn = ferTi !== 'undefined' ? ferTi.text_bn : ''
      const wareHouse = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.status === 1 && item.value === tmpData.to_warehouse_id)
      tmpData.warehouse_name = wareHouse !== 'undefined' ? wareHouse.text_en : ''
      tmpData.warehouse_name_bn = wareHouse !== 'undefined' ? wareHouse.text_bn : ''
      return JSON.parse(JSON.stringify(tmpData))
    },
    agentList (agentId) {
      const agent = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(fer => fer.value === agentId)
      this.formData.transport_agent_name = agent[0].text_en
    },
    getWarehouse (wareID) {
      const agent = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(fer => fer.value === wareID)
      this.formData.warehouse_name = agent[0].text_en
    },
    getdata () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${interGodownChallanReceiveUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, interGodownChallanReceiveStore, this.formData)
      }
      this.loading = false
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        // this.$bvModal.hide('modal-4')
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getChalanInfo () {
      this.loading = true
      const params = Object.assign({}, { lori_challan_no: this.formData.lori_challan_no })
      const response = await RestApi.getData(seedFertilizerServiceBaseUrl, loriChallanNoSearch, params)
      if (response.success) {
        this.formData.program_no = response.data.program_no
        this.formData.delivered_date = response.data.delivered_date
        this.formData.fertilizer_id = response.data.fertilizer_id
        this.formData.from_warehouse_id = response.data.from_godown_id
        this.formData.to_warehouse_id = response.data.to_godown_id
        this.formData.fiscal_year_id = response.data.fiscal_year_id
        this.formData.transport_agent_id = response.data.transport_agent_id
        this.formData.received_office_id = response.data.delivery_office_id
        this.formData.delivered_amount = response.data.amount_of_fertilizer
        const agent = this.$store.state.SeedsFertilizer.commonObj.transportAgentList.find(item => item.status === 1 && item.value === response.data.transport_agent_id)
        this.formData.transport_agent_name = agent !== 'undefined' ? agent.text_en : ''
        this.formData.transport_agent_name_bn = agent !== 'undefined' ? agent.text_bn : ''
        const wareHouse = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.status === 1 && item.value === response.data.to_godown_id)
        this.formData.warehouse_name = wareHouse !== 'undefined' ? wareHouse.text_en : ''
        this.formData.warehouse_name_bn = wareHouse !== 'undefined' ? wareHouse.text_bn : ''
        const ferTi = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.status === 1 && item.value === response.data.fertilizer_id)
        this.formData.fertilizer_name = ferTi !== 'undefined' ? ferTi.text_en : ''
        this.formData.fertilizer_name_bn = ferTi !== 'undefined' ? ferTi.text_bn : ''
        this.loading = false
      } else {
        this.formData.program_no = ''
        this.formData.delivered_date = ''
        this.formData.fertilizer_id = ''
        this.formData.from_warehouse_id = ''
        this.formData.to_warehouse_id = ''
        this.formData.fiscal_year_id = ''
        this.formData.transport_agent_id = ''
        this.formData.received_office_id = ''
        this.formData.delivered_amount = ''
        this.formData.transport_agent_name = ''
        this.formData.transport_agent_name_bn = ''
        this.formData.warehouse_name = ''
        this.formData.warehouse_name_bn = ''
        this.formData.fertilizer_name = ''
        this.formData.fertilizer_name_bn = ''
        this.loading = false
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
